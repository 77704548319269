exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aitools-js": () => import("./../../../src/pages/aitools.js" /* webpackChunkName: "component---src-pages-aitools-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careerscontact-js": () => import("./../../../src/pages/careerscontact.js" /* webpackChunkName: "component---src-pages-careerscontact-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-casestudy-js": () => import("./../../../src/pages/casestudy.js" /* webpackChunkName: "component---src-pages-casestudy-js" */),
  "component---src-pages-clinicians-js": () => import("./../../../src/pages/clinicians.js" /* webpackChunkName: "component---src-pages-clinicians-js" */),
  "component---src-pages-comparativestudy-js": () => import("./../../../src/pages/comparativestudy.js" /* webpackChunkName: "component---src-pages-comparativestudy-js" */),
  "component---src-pages-complianceregulatory-js": () => import("./../../../src/pages/complianceregulatory.js" /* webpackChunkName: "component---src-pages-complianceregulatory-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-diagnosticcenters-js": () => import("./../../../src/pages/diagnosticcenters.js" /* webpackChunkName: "component---src-pages-diagnosticcenters-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-healthcareaggregators-js": () => import("./../../../src/pages/healthcareaggregators.js" /* webpackChunkName: "component---src-pages-healthcareaggregators-js" */),
  "component---src-pages-hospitals-js": () => import("./../../../src/pages/hospitals.js" /* webpackChunkName: "component---src-pages-hospitals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mergestudy-js": () => import("./../../../src/pages/mergestudy.js" /* webpackChunkName: "component---src-pages-mergestudy-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-osteocheck-js": () => import("./../../../src/pages/osteocheck.js" /* webpackChunkName: "component---src-pages-osteocheck-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-protocall-js": () => import("./../../../src/pages/protocall.js" /* webpackChunkName: "component---src-pages-protocall-js" */),
  "component---src-pages-radiologists-js": () => import("./../../../src/pages/radiologists.js" /* webpackChunkName: "component---src-pages-radiologists-js" */),
  "component---src-pages-reachform-js": () => import("./../../../src/pages/reachform.js" /* webpackChunkName: "component---src-pages-reachform-js" */),
  "component---src-pages-reachus-js": () => import("./../../../src/pages/reachus.js" /* webpackChunkName: "component---src-pages-reachus-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-urgentflow-js": () => import("./../../../src/pages/urgentflow.js" /* webpackChunkName: "component---src-pages-urgentflow-js" */)
}

